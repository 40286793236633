export const appBuildAssetsDir = "/_nuxt/1737552145159/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"google-site-verification","content":"sLW7V50Dnjhi9gIwpytwMNMMYkX0XpYdReNWi0AX--A"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":null},{"name":"keywords","content":null},{},{"property":"og:title","content":"USA Baseball TV"},{"property":"og:type","content":"article"},{"property":"og:image","content":"https://usabaseball.watch.pixellot.tv/images/customer/logo.png"},{"property":"og:image:width","content":"1200"},{"property":"og:image:height","content":"630"},{"property":"og:description","content":""},{"property":"og:site_name","content":"USA Baseball TV"},{"property":"og:url","content":"https://usabaseball.watch.pixellot.tv"},{"property":"article:published_time","content":"1/22/2025"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:site","content":"https://www.twitter.com/usabaseball"},{"name":"twitter:title","content":"USA Baseball TV"},{"name":"twitter:description","content":""},{"name":"twitter:image:src","content":"https://usabaseball.watch.pixellot.tv/images/customer/logo.png"},{"name":"twitter:image:alt","content":"/images/customer/logo.png image"},{"name":"apple-itunes-app","content":"app-id=undefined, app-argument=https://usabaseball.watch.pixellot.tv"},{"property":"fb:app_id","content":"467239025253213"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/images/customer/favicon.png"}],"style":[{"children":"\n              :root {\n                --base-customer-color: #1152A5;\n                --base-primary-accent-color: #D22018;\n              }\n            ","type":"text/css"}],"script":[{"id":"imaSDK","type":"text/javascript","src":"https://imasdk.googleapis.com/js/sdkloader/ima3.js"},{"type":"text/javascript","src":"https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"}],"noscript":[],"title":"USA Baseball TV"}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true

export const spaLoadingTemplateOutside = false